import {
  GET_CASE_LIST,
  GET_CASE_LIST_SUCCESS,
  POST_NEW_CASE,
  POST_NEW_CASE_SUCCESS,
  GET_CASE_DETAIL,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  GET_CASE_REPORT,
  GET_LAWYER_GALAXY,
  GET_LAWYER_GALAXY_SUCCESS,
  GET_CASE_REPORT_SUCCESS,
  SET_CASE_IDS,
  GET_CASE_DETAIL_SUCCESS,
  GET_LAWYER_NEWS_SUCCESS,
  POST_KYC_DETAILS,
  POST_KYC_DETAILS_SUCCESS,
  POST_KYC_DOCUMENT,
  POST_KYC_DOCUMENT_SUCCESS,
  GET_LAWYER_NEWS,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST
} from '../contants';

export const postNewCase = (data) => ({
  type: POST_NEW_CASE,
  payload: data,
});

export const postNewCaseSuccess = (data) => ({
  type: POST_NEW_CASE_SUCCESS,
  payload: data,
});

export const getCaseList = (id) => ({
  type: GET_CASE_LIST,
  payload: id,
});

export const setCaseIds = (ID) => ({
  type: SET_CASE_IDS,
  payload: ID,
});

export const getCaseListSuccess = (data) => ({
  type: GET_CASE_LIST_SUCCESS,
  payload: data,
});

export const getCaseDetail = (ID) => ({
  type: GET_CASE_DETAIL,
  payload: ID,
});

export const getCaseDetailSuccess = (data) => ({
  type: GET_CASE_DETAIL_SUCCESS,
  payload: data,
});

export const uploadFile = (file) => ({
  type: UPLOAD_FILE,
  payload: file,
});

export const postKYCBasicDetails = (data) => ({
  type: POST_KYC_DETAILS,
  payload: data,
});

export const postKYCBasicDetailsSuccess = (data) => ({
  type: POST_KYC_DETAILS_SUCCESS,
  payload: data,
});

export const postKYCDocument = (data) => ({
  type: POST_KYC_DOCUMENT,
  payload: data,
});

export const postKYCDocumentSuccess = (data) => ({
  type: POST_KYC_DOCUMENT_SUCCESS,
  payload: data,
});

export const uploadFileSuccess = (filePath) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: filePath,
});

export const getCaseReport = (caseId) => ({
  type: GET_CASE_REPORT,
  payload: caseId,
});

export const getCaseReportSuccess = (data) => ({
  type: GET_CASE_REPORT_SUCCESS,
  payload: data,
});

export const getLawyerNews = (Id) => ({
  type: GET_LAWYER_NEWS,
  payload: Id,
});

export const getLawyerNewsSuccess = (data) => ({
  type: GET_LAWYER_NEWS_SUCCESS,
  payload: data,
});

export const getLawyerGalaxy = (Id) => ({
  type: GET_LAWYER_GALAXY,
  payload: Id,
});

export const getLawyerGalaxySuccess = (data) => ({
  type: GET_LAWYER_GALAXY_SUCCESS,
  payload: data,
});

export const getCompanyList = () => ({
  type: GET_COMPANY_LIST
});

export const getCompanyListSuccess = (data) => ({
  type: GET_COMPANY_LIST_SUCCESS,
  payload: data,
});


export const getCompanyDetail = (Id) => ({
  type: GET_COMPANY_DETAIL,
  payload: Id,
});

export const getCompanyDetailSuccess = (data) => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: data,
});