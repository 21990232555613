/* SETTINGS */
export const GET_SELECTED_CASE = 'GET_SELECTED_CASE';
export const GET_CASE_LIST = 'GET_CASE_LIST';
export const GET_CASE_LIST_SUCCESS = 'GET_CASE_LIST_SUCCESS';
export const POST_NEW_CASE = 'POST_NEW_CASE';
export const POST_NEW_CASE_SUCCESS = 'POST_NEW_CASE_SUCCESS';
export const GET_CASE_DETAIL = 'GET_CASE_DETAIL';
export const GET_CASE_DETAIL_SUCCESS = 'GET_CASE_DETAIL_SUCCESS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const GET_CASE_REPORT_SUCCESS = 'GET_CASE_REPORT_SUCCESS';
export const GET_CASE_REPORT = 'GET_CASE_REPORT';
export const SET_CASE_IDS = 'SET_CASE_IDS';
export const GET_LAWYER_NEWS_SUCCESS = 'GET_LAWYER_NEWS_SUCCESS';
export const GET_LAWYER_NEWS = 'GET_LAWYER_NEWS';
export const GET_LAWYER_GALAXY = 'GET_LAWYER_GALAXY';
export const GET_LAWYER_GALAXY_SUCCESS = 'GET_LAWYER_GALAXY_SUCCESS';
export const POST_KYC_DETAILS = 'POST_KYC_DETAILS';
export const POST_KYC_DETAILS_SUCCESS = 'POST_KYC_DETAILS_SUCCESS';

export const POST_KYC_DOCUMENT = 'POST_KYC_DOCUMENT';
export const POST_KYC_DOCUMENT_SUCCESS = 'POST_KYC_DOCUMENT_SUCCESS';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* HOME */
export const SECTOR_GET_LIST = 'SECTOR_GET_LIST';
export const SECTOR_GET_LIST_SUCCESS = 'SECTOR_GET_LIST_SUCCESS';
export const INDUSTRY_CHART = 'INDUSTRY_CHART';
export const INDUSTRY_CHART_SUCCESS = 'INDUSTRY_CHART_SUCCESS';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGI N_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_VIEW_ADD_COLUMNS = 'SURVEY_LIST_VIEW_ADD_COLUMNS';
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_WITH_CATEGORY_FILTER =
  'SURVEY_LIST_WITH_CATEGORY_FILTER';
export const SURVEY_LIST_WITH_KEYWORD_FILTER =
  'SURVEY_LIST_WITH_KEYWORD_FILTER';
export const SURVEY_LIST_WITH_LOCATION_FILTER =
  'SURVEY_LIST_WITH_LOCATION_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* INVESTOR LIST APP */
export const INVESTOR_LIST_VIEW_ADD_COLUMNS = 'INVESTOR_LIST_VIEW_ADD_COLUMNS';
export const INVESTOR_LIST_GET_LIST = 'INVESTOR_LIST_GET_LIST';
export const INVESTOR_LIST_GET_LIST_SUCCESS = 'INVESTOR_LIST_GET_LIST_SUCCESS';
export const INVESTOR_LIST_GET_FILTER_LIST_SUCCESS =
  'INVESTOR_LIST_GET_FILTER_LIST_SUCCESS';
export const INVESTOR_LIST_GET_FILTER_SUCCESS =
  'INVESTOR_LIST_GET_FILTER_SUCCESS';
export const INVESTOR_LIST_GET_LIST_ERROR = 'INVESTOR_LIST_GET_LIST_ERROR';
export const INVESTOR_LIST_SET_FILTER = 'INVESTOR_LIST_SET_FILTER';
export const INVESTOR_LIST_WITH_FILTER = 'INVESTOR_LIST_WITH_FILTER';
export const INVESTOR_LIST_SELECTED_ITEMS_CHANGE =
  'INVESTOR_LIST_SELECTED_ITEMS_CHANGE';
export const SET_DEAL_DATA = 'SET_DEAL_DATA';
export const FETCH_INVESTOR_LIST_WITH_PAGE = 'FETCH_INVESTOR_LIST_WITH_PAGE';
export const INVESTOR_LIST_GET_PAGGINATION_LIST_SUCCESS =
  'INVESTOR_LIST_GET_PAGGINATION_LIST_SUCCESS';
export const FETCH_PORTFOLIO_COMPANY_LIST = 'FETCH_PORTFOLIO_COMPANY_LIST';
export const SET_PORTFOLIO_COMPANY_LIST = 'SET_PORTFOLIO_COMPANY_LIST';
export const FETCH_CO_INVESTOR_LIST = 'FETCH_CO_INVESTOR_LIST';
export const SET_CO_INVESTOR_LIST = 'SET_CO_INVESTOR_LIST';
export const SET_CO_INVESTOR_COUNT = 'SET_CO_INVESTOR_COUNT';

/* COMPANY LIST */
export const COMPANY_LIST_VIEW_ADD_COLUMNS = 'COMPANY_LIST_VIEW_ADD_COLUMNS';
export const COMPANY_LIST_GET_LIST = 'COMPANY_LIST_GET_LIST';
export const COMPANY_LIST_GET_LIST_SUCCESS = 'COMPANY_LIST_GET_LIST_SUCCESS';
export const COMPANY_LIST_GET_FILTER_LIST_SUCCESS =
  'COMPANY_LIST_GET_FILTER_LIST_SUCCESS';
export const COMPANY_LIST_GET_FILTER_SUCCESS =
  'COMPANY_LIST_GET_FILTER_SUCCESS';
export const COMPANY_LIST_GET_LIST_ERROR = 'COMPANY_LIST_GET_LIST_ERROR';
export const COMPANY_LIST_SET_FILTER = 'COMPANY_LIST_SET_FILTER';
export const COMPANY_LIST_WITH_FILTER = 'COMPANY_LIST_WITH_FILTER';
export const COMPANY_LIST_SELECTED_ITEMS_CHANGE =
  'COMPANY_LIST_SELECTED_ITEMS_CHANGE';
export const FETCH_COMPANY_LIST_WITH_PAGE = 'FETCH_COMPANY_LIST_WITH_PAGE';
export const COMPANY_LIST_GET_PAGGINATION_LIST_SUCCESS =
  'COMPANY_LIST_GET_PAGGINATION_LIST_SUCCESS';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const CLEAR_GLOBAL_SEARCH = 'CLEAR_GLOBAL_SEARCH';
export const FETCH_SPECIALITY_SUGG = 'FETCH_SPECIALITY_SUGG';
export const FETCH_SPECIALITY_SUGG_SUCCESS = 'FETCH_SPECIALITY_SUGG_SUCCESS';
export const CLEAR_SPECIALITY_SUGG = 'CLEAR_SPECIALITY_SUGG';
export const FETCH_COMPANY_NAME_SUCCESS = 'FETCH_COMPANY_NAME_SUCCESS';
export const FETCH_COMPANY_NAME = 'FETCH_COMPANY_NAME';
export const GLOBAL_SEARCH_LIST = 'GLOBAL_SEARCH_LIST';
export const ADD_COMPANY_ACTION = 'ADD_COMPANY_ACTION';
export const ADD_COMPANY_ACTION_SUCCESS = 'ADD_COMPANY_ACTION_SUCCESS';
export const UPDATE_COMPANY_ACTION = 'UPDATE_COMPANY_ACTION';
export const UPDATE_COMPANY_ACTION_SUCCESS = 'UPDATE_COMPANY_ACTION_SUCCESS';
export const DELETE_COMPANY_ACTION_SUCCESS = 'DELETE_COMPANY_ACTION_SUCCESS';
export const SORT_COMPANY_LIST_BY_FILTER = 'SORT_COMPANY_LIST_BY_FILTER';
export const SAVE_USER_SEARCH = 'SAVE_USER_SEARCH';
export const GET_SAVED_SEARCH = 'GET_SAVED_SEARCH';
export const SAVE_USER_SEARCH_SUCCESS = 'SAVE_USER_SEARCH_SUCCESS';
export const UK_COMPANY_LIST_GET_FILTER_LIST_SUCCESS =
  'UK_COMPANY_LIST_GET_FILTER_LIST_SUCCESS';
export const UK_COMPANY_LIST_GET_FILTER_LIST =
  'UK_COMPANY_LIST_GET_FILTER_LIST';
export const GET_SAVED_SEARCH_LIST_SUCCESS = 'GET_SAVED_SEARCH_LIST_SUCCESS';
export const GET_SAVED_SEARCH_LIST = 'GET_SAVED_SEARCH_LIST';
export const CLEAR_COMPANY_SUGG = 'CLEAR_COMPANY_SUGG';
export const FETCH_REGION_SUGG = 'FETCH_REGION_SUGG';
export const CLEAR_REGION_SUGG = 'CLEAR_REGION_SUGG';
export const FETCH_LOCALITY_SUGG = 'FETCH_LOCALITY_SUGG';
export const CLEAR_LOCALITY_SUGG = 'CLEAR_LOCALITY_SUGG';
export const FETCH_REGION_SUGG_SUCCESS = 'FETCH_REGION_SUGG_SUCCESS';
export const FETCH_LOCALITY_SUGG_SUCCESS = 'FETCH_LOCALITY_SUGG_SUCCESS';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';

/* COMPANY DETAIL */
export const FETCH_COMPANY_DETAIL_DATA = 'FETCH_COMPANY_DETAIL_DATA';
export const GET_SEARCH_COMPANY_LIST = 'GET_SEARCH_COMPANY_LIST';
export const GET_SEARCH_COMPANY_LIST_SUCCESS =
  'GET_SEARCH_COMPANY_LIST_SUCCESS';
export const FETCH_TRUST_PILOT_REVIEWS = 'FETCH_TRUST_PILOT_REVIEWS';

export const FETCH_COMPANY_NEWS_DATA = 'FETCH_COMPANY_NEWS_DATA';
export const FETCH_NEWS_DATA = 'FETCH_NEWS_DATA';


export const DELETE_COMMENT = 'DELETE_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';

export const FETCH_USER_COMMENTS = 'FETCH_USER_COMMENTS';
export const POST_USER_COMMENT = 'POST_USER_COMMENT';
export const POST_USER_COMMENT_SUCCESS = 'POST_USER_COMMENT_SUCCESS';
export const ADD_COMPANY_DETAIL_ACTION = 'ADD_COMPANY_DETAIL_ACTION';

export const UPDATE_COMPANY_DETAIL_ACTION = 'UPDATE_COMPANY_DETAIL_ACTION';

export const FETCH_EMPLOYEE_COUNT = 'FETCH_EMPLOYEE_COUNT';
export const FETCH_WEB_TRAFFIC_DETAIL = 'FETCH_WEB_TRAFFIC_DETAIL';
export const FETCH_DISTRESS_DETAIL = 'FETCH_DISTRESS_DETAIL';
export const FETCH_COMPETITORS_DETAIL = 'FETCH_COMPETITORS_DETAIL';
export const FETCH_SUPPLY_CHAIN_DETAIL = 'FETCH_SUPPLY_CHAIN_DETAIL';

export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'FETCH_CUSTOMER_DATA_SUCCESS';

/* WATCH LIST */
export const WATCH_LIST_GET_LIST = 'WATCH_LIST_GET_LIST';
export const WATCH_LIST_GET_LIST_SUCCESS = 'WATCH_LIST_GET_LIST_SUCCESS';
export const WATCH_LIST_GET_LIST_ERROR = 'WATCH_LIST_GET_LIST_ERROR';
export const FETCH_WATCH_LIST_WITH_PAGE = 'FETCH_WATCH_LIST_WITH_PAGE';
export const WATCH_LIST_GET_PAGGINATION_LIST_SUCCESS =
  'WATCH_LIST_GET_PAGGINATION_LIST_SUCCESS';
export const REMOVE_WATCH_LIST = 'REMOVE_WATCH_LIST';

/* PORTFOLIO LIST */
export const PORTFOLIO_LIST_GET_LIST = 'PORTFOLIO_LIST_GET_LIST';
export const PORTFOLIO_LIST_GET_LIST_SUCCESS =
  'PORTFOLIO_LIST_GET_LIST_SUCCESS';
export const PORTFOLIO_LIST_GET_LIST_ERROR = 'PORTFOLIO_LIST_GET_LIST_ERROR';
export const FETCH_PORTFOLIO_LIST_WITH_PAGE = 'FETCH_PORTFOLIO_LIST_WITH_PAGE';
export const PORTFOLIO_LIST_GET_PAGGINATION_LIST_SUCCESS =
  'PORTFOLIO_LIST_GET_PAGGINATION_LIST_SUCCESS';
export const REMOVE_PORTFOLIO_LIST = 'REMOVE_PORTFOLIO_LIST';

/* PURSUE LIST */
export const PURSUE_LIST_GET_LIST = 'PURSUE_LIST_GET_LIST';
export const PURSUE_LIST_GET_LIST_SUCCESS = 'PURSUE_LIST_GET_LIST_SUCCESS';
export const PURSUE_LIST_GET_LIST_ERROR = 'PURSUE_LIST_GET_LIST_ERROR';
export const FETCH_PURSUE_LIST_WITH_PAGE = 'FETCH_PURSUE_LIST_WITH_PAGE';
export const PURSUE_LIST_GET_PAGGINATION_LIST_SUCCESS =
  'PURSUE_LIST_GET_PAGGINATION_LIST_SUCCESS';
export const REMOVE_PURSUE_LIST = 'REMOVE_PURSUE_LIST';

/*  USERS MANAGEMENT */
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const SAVE_EDIT_USER = 'SAVE_EDIT_USER';
export const SAVE_ADD_USER = 'SAVE_ADD_USER';
export const SET_COMPANY_DEEP_DIVE = 'SET_COMPANY_DEEP_DIVE';
export const SET_COMPANY_OFFICER_LIST = 'SET_COMPANY_OFFICER_LIST';
export const FETCH_TRUST_PILOT_REVIEWS_SUCCESS =
  'FETCH_TRUST_PILOT_REVIEWS_SUCCESS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_OFFICER_GALAXY = 'SET_OFFICER_GALAXY';
export const SET_SUPPLY_GALAXY = 'SET_SUPPLY_GALAXY';
export const SET_SIGNALS_DATA = 'SET_SIGNALS_DATA';
export const SET_NEWS_LIST_DATA = 'SET_NEWS_LIST_DATA';
export const SET_NEWS_DATA = 'SET_NEWS_DATA';
export const FETCH_USER_COMMENTS_SUCCESS = 'FETCH_USER_COMMENTS_SUCCESS';
export const FETCH_WEB_TRAFFIC_DETAIL_SUCCESS =
  'FETCH_WEB_TRAFFIC_DETAIL_SUCCESS';
export const FETCH_DISTRESS_DETAIL_SUCCESS = 'FETCH_DISTRESS_DETAIL_SUCCESS';
export const FETCH_COMPANY_NEWS_DATA_SUCCESS =
  'FETCH_COMPANY_NEWS_DATA_SUCCESS';
export const FETCH_COMPETITORS_DETAIL_SUCCESS =
  'FETCH_COMPETITORS_DETAIL_SUCCESS';
export const FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS =
  'FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS';
export const ADD_COMPANY_DETAIL_ACTION_SUCCESS =
  'ADD_COMPANY_DETAIL_ACTION_SUCCESS';
export const UPDATE_COMPANY_DETAIL_ACTION_SUCCESS =
  'UPDATE_COMPANY_DETAIL_ACTION_SUCCESS';
export const DELETE_COMPANY_DETAIL_ACTION_SUCCESS =
  'DELETE_COMPANY_DETAIL_ACTION_SUCCESS';
export const FETCH_EMPLOYEE_COUNT_SUCCESS = 'FETCH_EMPLOYEE_COUNT_SUCCESS';

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';

export const GET_COMPANY_DETAIL = 'GET_COMPANY_DETAIL';
export const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS';