import {
  FETCH_COMPANY_DETAIL_DATA,
  SET_COMPANY_DEEP_DIVE,
  SET_COMPANY_OFFICER_LIST,
  FETCH_TRUST_PILOT_REVIEWS_SUCCESS,
  SET_COMPANY_DETAILS,
  SET_OFFICER_GALAXY,
  SET_SUPPLY_GALAXY,
  SET_SIGNALS_DATA,
  SET_NEWS_LIST_DATA,
  SET_NEWS_DATA,
  FETCH_USER_COMMENTS_SUCCESS,
  FETCH_WEB_TRAFFIC_DETAIL_SUCCESS,
  FETCH_DISTRESS_DETAIL_SUCCESS,
  FETCH_COMPANY_NEWS_DATA_SUCCESS,
  FETCH_COMPETITORS_DETAIL_SUCCESS,
  FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS,
  ADD_COMPANY_DETAIL_ACTION_SUCCESS,
  UPDATE_COMPANY_DETAIL_ACTION_SUCCESS,
  DELETE_COMPANY_DETAIL_ACTION_SUCCESS,
  FETCH_EMPLOYEE_COUNT_SUCCESS,
  FETCH_CUSTOMER_DATA_SUCCESS,
} from '../contants';

const INIT_STATE = {
  deepdive: null,
  officers: null,
  officersGalaxy: {
    "nodes": [
      {
        "id": "root",
        "isroot": true,
        "topic": null
      },
      {
        "id": 1,
        "group": 1,
        "topic": "Fatima Ali contract"
      },
      {
        "id": 28853333455,
        "group": 2,
        "topic": "Fatima Ali"
      },
      {
        "id": 28853333487,
        "group": 2,
        "topic": "XYZ bank of qatar"
      },
      {
        "id": 28853333441,
        "group": 2,
        "topic": "Agent Vinod"
      }
    ],
    "links": [
      {
        "source": "root",
        "target": 1
      },
      {
        "source": 1,
        "target": 28853333455
      },
      {
        "source": 1,
        "target": 28853333487
      },
      {
        "source": 1,
        "target": 28853333441
      }
    ]
  },
  companyDetails: null,
  loading: false,
  signalsList: null,
  newsList: null,
  newsData: null,
  supplyGalaxy: null,
  trustPilotReviews: [],
  typeNews: null,
  categoryNews: null,
  allNews: null,
  userComments: null,
  webTrafficDetails: {
    timeseries: {
      direct: [0, 0, 0, 0, 0, 0, 882, 4254, 0, 0, 0, 98, 176],
      referral: [1386, 1396, 0, 2124, 0, 0, 0, 0, 428, 0, 0, 0, 43],
      paid: [0, 0, 0, 0, 0, 0, 0, 543, 382, 0, 0, 0, 57],
      social: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      search: [90, 0, 0, 0, 0, 989, 312, 0, 620, 4, 509, 0, 376],
      Total: [1476, 1396, 0, 2124, 0, 989, 1194, 4797, 1430, 4, 509, 98, 652],
    },
  },
  distressDetails: null,
  competitorsDetails: null,
  supplyChainData: null,
  employeeCountDataSample: false,
  employeeCountData: null,
  employeeCountData1: [
    {
      date: {
        year: 2019,
        month: 12,
      },
      count: 45,
    },
    {
      date: {
        year: 2020,
        month: 1,
      },
      count: 46,
    },
    {
      date: {
        year: 2020,
        month: 2,
      },
      count: 46,
    },
    {
      date: {
        year: 2020,
        month: 3,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 4,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 5,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 6,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 7,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 8,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 9,
      },
      count: 47,
    },
    {
      date: {
        year: 2020,
        month: 10,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 11,
      },
      count: 48,
    },
    {
      date: {
        year: 2020,
        month: 12,
      },
      count: 49,
    },
    {
      date: {
        year: 2021,
        month: 1,
      },
      count: 49,
    },
    {
      date: {
        year: 2021,
        month: 2,
      },
      count: 49,
    },
    {
      date: {
        year: 2021,
        month: 3,
      },
      count: 49,
    },
    {
      date: {
        year: 2021,
        month: 4,
      },
      count: 49,
    },
    {
      date: {
        year: 2021,
        month: 5,
      },
      count: 50,
    },
    {
      date: {
        year: 2021,
        month: 6,
      },
      count: 51,
    },
    {
      date: {
        year: 2021,
        month: 7,
      },
      count: 52,
    },
    {
      date: {
        year: 2021,
        month: 8,
      },
      count: 52,
    },
    {
      date: {
        year: 2021,
        month: 9,
      },
      count: 53,
    },
    {
      date: {
        year: 2021,
        month: 10,
      },
      count: 54,
    },
    {
      date: {
        year: 2021,
        month: 11,
      },
      count: 55,
    },
    {
      date: {
        year: 2021,
        month: 12,
      },
      count: 55,
    },
  ],
  customerData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_DETAILS:
      return { ...state, companyDetails: action.payload.data, loading: false };
    case FETCH_COMPANY_DETAIL_DATA:
      return { ...state, loading: true, companyDetails: null };
    case FETCH_COMPANY_NEWS_DATA_SUCCESS:
      return {
        ...state,
        categoryNews: action.payload.data[2],
        typeNews: action.payload.data[0],
        allNews: action.payload.data[1],
      };
    case SET_COMPANY_OFFICER_LIST:
      return { ...state, officers: action.payload.data };
    case SET_COMPANY_DEEP_DIVE:
      return { ...state, deepdive: action.payload.data };
    case SET_OFFICER_GALAXY:
      return { ...state, officersGalaxy: action.payload.data };
    case SET_SUPPLY_GALAXY:
      return { ...state, supplyGalaxy: action.payload.data };
    case SET_SIGNALS_DATA:
      return { ...state, signalsList: action.payload.data };
    case SET_NEWS_LIST_DATA:
      return { ...state, newsList: action.payload.data };
    case SET_NEWS_DATA:
      return { ...state, newsData: action.payload.data };
    case FETCH_USER_COMMENTS_SUCCESS:
      return { ...state, userComments: action.payload.data };
    case FETCH_WEB_TRAFFIC_DETAIL_SUCCESS:
      return { ...state };
    case FETCH_DISTRESS_DETAIL_SUCCESS:
      return { ...state, distressDetails: action.payload.data };
    case FETCH_COMPETITORS_DETAIL_SUCCESS:
      return { ...state, competitorsDetails: action.payload.data };
    case FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS:
      return { ...state, supplyChainData: action.payload.data };
    case ADD_COMPANY_DETAIL_ACTION_SUCCESS:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          type: action.payload.data.type,
        },
      };
    case FETCH_EMPLOYEE_COUNT_SUCCESS:
      return {
        ...state,
        employeeCountData: action.payload.data
          ? action.payload.data
          : state.employeeCountData1,
        employeeCountDataSample: action.payload.data === null,
      };
    case UPDATE_COMPANY_DETAIL_ACTION_SUCCESS:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          type: action.payload.data.type,
        },
      };
    case DELETE_COMPANY_DETAIL_ACTION_SUCCESS:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          type: null,
        },
      };
    case FETCH_CUSTOMER_DATA_SUCCESS:
      return { ...state, customerData: action.payload.data };
    case FETCH_TRUST_PILOT_REVIEWS_SUCCESS:
      return {
        ...state,
        trustPilotReviews: action.payload.data.trustpilot_review,
      };
    default:
      return { ...state };
  }
};
