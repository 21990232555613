import axios from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  FETCH_COMPANY_DETAIL_DATA,
  FETCH_TRUST_PILOT_REVIEWS,
  FETCH_COMPANY_NEWS_DATA,
  FETCH_USER_COMMENTS,
  POST_USER_COMMENT,
  FETCH_NEWS_DATA,
  FETCH_WEB_TRAFFIC_DETAIL,
  FETCH_DISTRESS_DETAIL,
  FETCH_COMPETITORS_DETAIL,
  FETCH_SUPPLY_CHAIN_DETAIL,
  ADD_COMPANY_DETAIL_ACTION,
  UPDATE_COMPANY_DETAIL_ACTION,
  FETCH_EMPLOYEE_COUNT,
  FETCH_CUSTOMER_DATA,
  DELETE_COMMENT,
  EDIT_COMMENT,
} from '../contants';

import {
  getCompanyDetails,
  getCompanyDeepDive,
  getSignalsDetails,
  // getSupplysGalaxy,
  // getNews,
  getCompanyNewsSuccess,
  fetchEmployeeCountSuccess,
  getNews1,
  getUserCommentsSuccess,
  postUserCommentSuccess,
  fetchWebTrafficDetailSuccess,
  fetchDistressDetailSuccess,
  fetchCompetitorsDetailSuccess,
  // deleteCommentSuccess,
  // editCommentSuccess,
  fetchSupplyDetailSuccess,
  addCompanyDetailActionByUserSuccess,
  updateCompanyDetailActionByUserSuccess,
  deleteCompanyDetailActionByUserSuccess,
  fetchCustomerDataSuccess,
  fetchTrustPilotReviewsSuccess
} from './actions';

const getCompanyDetailReq = async (cogentId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/company_details/${cogentId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const fetchTrustPilotReviewsRequest = async (cogentId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/trustpilot_review/${cogentId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

// const getCompanyOfficersGalaxyReq = async (cId) => {
//   // eslint-disable-next-line no-return-await
//   return await new Promise((success) => {
//     success(
//       axios({
//         // Endpoint to send files
//         url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/officers_galaxy/${cId}`,
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           // Add any auth token here
//         },
//       })
//         .then((res) => res)
//         .catch(() => {})
//     );
//   })
//     .then((response) => response)
//     .catch((error) => error);
// };

// const getSupplyGalaxyReq = async (spId) => {
//   // eslint-disable-next-line no-return-await
//   return await new Promise((success) => {
//     success(
//       axios({
//         // Endpoint to send files
//         url: `${process.env.REACT_APP_HOST}:8002/company_supply/${spId}`,
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           // Add any auth token here
//         },
//       })
//         .then((res) => res)
//         .catch(() => {})
//     );
//   })
//     .then((response) => response)
//     .catch((error) => error);
// };

// const getNewsReq = async (company) => {
//   // eslint-disable-next-line no-return-await
//   return await new Promise((success) => {
//     success(
//       axios({
//         // Endpoint to send files
//         url: `${process.env.REACT_APP_HOST}:8002/news`,
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           // Add any auth token here
//         },
//         params: {
//           keyword: company,
//         },
//       })
//         .then((res) => res)
//         .catch(() => {})
//     );
//   })
//     .then((response) => response)
//     .catch((error) => error);
// };

const getNewsReq1 = async (params_) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/newsData`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
        params: params_,
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};
const addCompanyDetailActionByUserRequest = async (val) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${val.userId}/companylist`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          user_id: val.userId,
          company_id: val.companyId,
          type: val.type,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateCompanyDetailActionByUserRequest = async (val) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${val.userId}/companylist/${val.companyId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          type: val.type,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const deleteCompanyDetailActionByUserRequest = async (val) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${val.userId}/companylist/${val.companyId}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getCompanyDeepDiveRequest = async (spId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/deep_dive/${spId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSignalsRequest = async (spId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/financeSignals/${spId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSignalsRequest1 = async (pId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/financeSignalsCH/${pId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const fetchCommentsRequest = async (cogentId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/company/${cogentId}/comment`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const postUserCommentRequest = async ({
  userId,
  cId,
  userComment,
  commentVisible,
}) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${userId}/comment`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          user_id: userId,
          company_id: cId,
          visible: commentVisible,
          comment: userComment,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const deleteCommentRequest = async ({ userId, cId, createdAt }) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${userId}/comment/${cId}/${createdAt}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(() => false)
        .catch(() => {})
    );
  })
    .then(() => false)
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const editCommentRequest = async ({
  userId,
  cId,
  createdAt,
  isVisible,
  editedComment,
}) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST3}:${process.env.REACT_APP_PORT3}/user/${userId}/comment/${cId}/${createdAt}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          comment: editedComment,
          visible: isVisible,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getWebTrafficDetailRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/webTraffic/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getDistressDetailRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/distress/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getCompetitorsDetailRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/competitors/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSupplyDetailRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/company_supply_data/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const fetchEmployeeCountRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/employeeCount/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const fetchCustomerDataRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:8002/company_customer_data/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

const getCompanyNewsRequest = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        url: `${process.env.REACT_APP_HOST}:8002/news/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => res)
        .catch(() => {})
    );
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCompanyDetail({ payload }) {
  try {
    const response = yield call(getCompanyDetailReq, payload.cogentId);
    yield put(getCompanyDetails(response));
    if (response.data.sp_cid !== null) {
      const signalsData = yield call(getSignalsRequest, response.data.sp_cid);
      yield put(getSignalsDetails(signalsData));
    } else {
      const signalsData = yield call(getSignalsRequest1, response.data.ch_cid);
      yield put(getSignalsDetails(signalsData));
    }
    try {
      const data = yield call(getCompanyDeepDiveRequest, payload.cogentId);
      yield put(getCompanyDeepDive(data));
    } catch (error) {
      //
    }
  } catch (error) {
    // yield put(getCompanyDetails(error));
  }
}

function* fetchTrustPilotReviews({ payload }) {
  try {
    const response = yield call(
      fetchTrustPilotReviewsRequest,
      payload.cogentId
    );
    yield put(fetchTrustPilotReviewsSuccess(response));
  } catch (error) {
    // yield put(getCompanyDetails(error));
  }
}

function* getCompanyNews(data) {
  try {
    const response = yield call(getCompanyNewsRequest, data.payload);
    yield put(getCompanyNewsSuccess(response));
  } catch (error) {
    // yield put(getCompanyDetails(error));
  }
}

function* fetchComments({ payload }) {
  try {
    const response = yield call(fetchCommentsRequest, payload.cogentId);
    yield put(getUserCommentsSuccess(response));
  } catch (e) {
    //
  }
}

function* postUserComment({ payload }) {
  try {
    const response = yield call(postUserCommentRequest, payload);
    yield put(postUserCommentSuccess(response));
    const responseComments = yield call(
      fetchCommentsRequest,
      payload.cId,
      payload.userId
    );
    yield put(getUserCommentsSuccess(responseComments));
  } catch (e) {
    //
  }
}

function* deleteUserComment({ payload }) {
  try {
    yield call(deleteCommentRequest, payload);

    const responseComments = yield call(fetchCommentsRequest, payload.cId);
    yield put(getUserCommentsSuccess(responseComments));
  } catch (e) {
    console.log(e);
  }
}
function* editComment({ payload }) {
  try {
    yield call(editCommentRequest, payload);
    const responseComments = yield call(fetchCommentsRequest, payload.cId);
    yield put(getUserCommentsSuccess(responseComments));
  } catch (e) {
    //
  }
}

function* featchNews({ payload }) {
  try {
    const response = yield call(getNewsReq1, payload);
    yield put(getNews1(response));
  } catch (e) {
    //
  }
}

function* fetchWebTrafficDetail(payload) {
  try {
    const response = yield call(getWebTrafficDetailRequest, payload.payload);
    yield put(fetchWebTrafficDetailSuccess(response));
  } catch (e) {
    //
  }
}

function* fetchDistressDetail(payload) {
  try {
    const response = yield call(getDistressDetailRequest, payload.payload);
    yield put(fetchDistressDetailSuccess(response));
  } catch (e) {
    //
  }
}

function* fetchCompetitorsDetail(payload) {
  try {
    const response = yield call(getCompetitorsDetailRequest, payload.payload);
    yield put(fetchCompetitorsDetailSuccess(response));
  } catch (e) {
    //
  }
}

function* fetchSupplyDetail(payload) {
  try {
    const response = yield call(getSupplyDetailRequest, payload.payload);
    yield put(fetchSupplyDetailSuccess(response));
  } catch (e) {
    //
  }
}

function* addCompanyDetailActionByUser(data) {
  try {
    const response = yield call(
      addCompanyDetailActionByUserRequest,
      data.payload
    );
    yield put(addCompanyDetailActionByUserSuccess(response));
  } catch (error) {
    // yield put(getCompanyListError(error));
  }
}

function* fetchEmployeeCount(id) {
  try {
    const response = yield call(fetchEmployeeCountRequest, id.payload);
    yield put(fetchEmployeeCountSuccess(response));
  } catch (error) {
    // yield put(getCompanyListError(error));
  }
}

function* updateCompanyDetailActionByUser(data) {
  try {
    if (data.payload.current === data.payload.type) {
      yield call(deleteCompanyDetailActionByUserRequest, data.payload);
      yield put(deleteCompanyDetailActionByUserSuccess(data.payload));
    } else {
      const response = yield call(
        updateCompanyDetailActionByUserRequest,
        data.payload
      );
      yield put(updateCompanyDetailActionByUserSuccess(response));
    }
  } catch (error) {
    // yield put(getCompanyListError(error));
  }
}

function* fetchCustomerData(data) {
  try {
    const response = yield call(fetchCustomerDataRequest, data.payload);
    yield put(fetchCustomerDataSuccess(response));
  } catch (error) {
    // yield put(getCompanyListError(error));
  }
}

export function* watchFetchDetails() {
  yield takeEvery(FETCH_COMPANY_DETAIL_DATA, getCompanyDetail);
}

export function* watchFetchTrustPilotReviews() {
  yield takeEvery(FETCH_TRUST_PILOT_REVIEWS, fetchTrustPilotReviews);
}

export function* watchFetchNewsData() {
  yield takeEvery(FETCH_COMPANY_NEWS_DATA, getCompanyNews);
}

export function* watchFetchUserComments() {
  yield takeEvery(FETCH_USER_COMMENTS, fetchComments);
}

export function* watchPostCommment() {
  yield takeEvery(POST_USER_COMMENT, postUserComment);
}

export function* watchDeleteComment() {
  yield takeEvery(DELETE_COMMENT, deleteUserComment);
}
export function* watchEditCommment() {
  yield takeEvery(EDIT_COMMENT, editComment);
}

export function* watchFetchNews() {
  yield takeEvery(FETCH_NEWS_DATA, featchNews);
}

export function* watchWebTrafficDetail() {
  yield takeEvery(FETCH_WEB_TRAFFIC_DETAIL, fetchWebTrafficDetail);
}

export function* watchDistressDetail() {
  yield takeEvery(FETCH_DISTRESS_DETAIL, fetchDistressDetail);
}

export function* watchCompetitorsDetail() {
  yield takeEvery(FETCH_COMPETITORS_DETAIL, fetchCompetitorsDetail);
}

export function* watchSupplyDetail() {
  yield takeEvery(FETCH_SUPPLY_CHAIN_DETAIL, fetchSupplyDetail);
}

export function* watchAddCompanyDetailAction() {
  yield takeEvery(ADD_COMPANY_DETAIL_ACTION, addCompanyDetailActionByUser);
}

export function* watchUpdateCompanyDetailAction() {
  yield takeEvery(
    UPDATE_COMPANY_DETAIL_ACTION,
    updateCompanyDetailActionByUser
  );
}

export function* watchEmployeeCount() {
  yield takeEvery(FETCH_EMPLOYEE_COUNT, fetchEmployeeCount);
}

export function* watchCustomerData() {
  yield takeEvery(FETCH_CUSTOMER_DATA, fetchCustomerData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchDetails),
    fork(watchFetchTrustPilotReviews),
    fork(watchFetchNews),
    fork(watchFetchUserComments),
    fork(watchPostCommment),
    fork(watchDeleteComment),
    fork(watchEditCommment),
    fork(watchWebTrafficDetail),
    fork(watchDistressDetail),
    fork(watchFetchNewsData),
    fork(watchCompetitorsDetail),
    fork(watchSupplyDetail),
    fork(watchAddCompanyDetailAction),
    fork(watchUpdateCompanyDetailAction),
    fork(watchEmployeeCount),
    fork(watchCustomerData),
  ]);
}
