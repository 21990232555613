import { combineReducers } from 'redux';
import mycases from './mycases/reducer';
import authUser from './auth/reducer';
import settings from './settings/reducer';
import companyDetail from './companyDetails/reducer';

import menu from './menu/reducer';

const appReducer = combineReducers({
  companyDetail,
  mycases,
  authUser,
  settings,
  menu,
});

const reducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default reducer;
