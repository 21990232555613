// import axiosConfig from "../axiosConfig";
import { getCurrentUser, setCurrentUser } from 'helpers/Utils';
import { NotificationManager } from 'components/common/react-notifications';

import {
  SET_CASE_IDS,
  GET_CASE_LIST,
  GET_CASE_LIST_SUCCESS,
  GET_CASE_DETAIL,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE,
  POST_KYC_DETAILS_SUCCESS,
  GET_COMPANY_LIST,
  GET_CASE_DETAIL_SUCCESS,
  GET_LAWYER_NEWS_SUCCESS,
  GET_LAWYER_GALAXY_SUCCESS,
  GET_LAWYER_NEWS,
  GET_LAWYER_GALAXY,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL,
} from '../contants';

const INIT_STATE = {
  currentUser: getCurrentUser(),
  title: 'Dashboard',
  companyList: null,
  companyPolicy: null,
  companyDetail: null,
  companyFinance: null,
  companyDeepDive: null,
  policyData: null,
  complainceDocuments: [
    {
      id: 1,
      title: 'Commitment Clauses',
      note: 'Responses from the Subject Entity/Inspectors Notes',
      eval: 'Item Evaluation (to be filled out by the Ministry)',
      list: [],
    },
  ],
  lawyerNews: null,
  KYCDetailsUploaded: false,
  galaxy: null,
  documentsList: [
    {
      original_filename: 'Mortgage Agreement',
      filename: null,
      file_type_id: 1,
      filePath: null,
    },
    {
      original_filename: 'No Objection Certificate (NOC)',
      filename: null,
      file_type_id: 2,
      filePath: null,
    },
    {
      original_filename: 'Power of Attorney',
      filename: null,
      file_type_id: 3,
      filePath: null,
    },
    {
      original_filename: 'Property Handover Certificate',
      filename: null,
      file_type_id: 4,
      filePath: null,
    },
    {
      original_filename: 'Property Survey Plan',
      filename: null,
      file_type_id: 5,
      filePath: null,
    },
    {
      original_filename: 'Receipt of Payment',
      filename: null,
      file_type_id: 6,
      filePath: null,
    },
    {
      original_filename: 'Sale Agreement',
      filename: null,
      file_type_id: 7,
      filePath: null,
    },
    {
      original_filename: 'Title Deed',
      filename: null,
      file_type_id: 8,
      filePath: null,
    },
  ],
  caseList: null,
  allCaseList: null,
  selectedCaseId: null,
  selectedTab: 'details',
  selectedLawyerId: null,
  selectedCaseDetail: null,
  selectedFile: null,
  selectedCaseDocuments: null,
  caseReport: null,
  property_details: [
    {
      case_id: 1,
      property_type: 'A luxurious 4-bedroom penthouse',
      property_address: 'West Bay, Diplomatic Street, Doha, Qatar',
    },
    {
      case_id: 2,
      property_type: 'A commercial building',
      property_address: 'Lusail City, Doha, Qatar',
    },
    {
      case_id: 3,
      property_type: 'A residential apartment',
      property_address: 'Al Sadd, Doha, Qatar',
    },
    {
      case_id: 4,
      property_type: 'A 2-bedroom apartment',
      property_address: 'Al Sadd, Doha, Qatar',
    },
  ],
  selectedPropertyDetails: null,
  isLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_KYC_DETAILS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const curU = getCurrentUser();
      curU.user_kyc_status =
        action.payload.status === 201 ? action.payload.user_kyc_status : 0;
      setCurrentUser(curU);
      return {
        ...state,
        isLoading: false,
        KYCDetailsUploaded: true,
        currentUser: curU,
      };
    case GET_CASE_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CASE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        caseList: action.payload.data.length ? action.payload.data : null,
        selectedPropertyDetails: state.property_details.filter(
          (p) => p.case_id === action.payload.data.length > 0 ? action.payload.data[0].case_id : 1
        )[0],
      };
    // case POST_KYC_DETAILS_SUCCESS:
    //   return{
    //     ...state,
    //     KYCDetailsUploaded: true
    //   }
    case SET_CASE_IDS:
      return {
        ...state,
        selectedTab: action.payload.tab,
        selectedCaseId: action.payload.caseId,
        selectedLawyerId: action.payload.lawyerId,
      };
    case GET_CASE_DETAIL:
      return {
        ...state,
        selectedPropertyDetails: state.property_details.filter(
          (p) => p.case_id === action.payload.caseId
        )[0],
        selectedCaseDetail: state.caseList.filter(
          (c) => c.case_id === action.payload.caseId
        )[0],
        caseReport: null,
        selectedCaseDocuments: null,
        isLoading: 2,
        lawyerNews: null,
        galaxy: null,
      };
    case GET_CASE_DETAIL_SUCCESS:
      return {
        ...state,
        caseReport: action.payload.report_path,
        selectedCaseDocuments:
          action.payload.data &&
          action.payload.data.length > 0 &&
          action.payload.data.length < 9
            ? [
                ...state.documentsList.map((d) =>
                  action.payload.data.filter(
                    (s) => s.file_type_id === d.file_type_id
                  ).length > 0
                    ? action.payload.data.filter(
                        (s) => s.file_type_id === d.file_type_id
                      )[0]
                    : d
                ),
              ]
            : state.documentsList,
        isLoading: false,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        selectedFile: action.payload.file_type_id,
        isLoading: 3,
      };
    case GET_LAWYER_NEWS:
      return {
        ...state,
        lawyerNews: null,
        isLoading: 4,
      };
    case GET_LAWYER_NEWS_SUCCESS:
      return {
        ...state,
        lawyerNews: action.payload.data,
        isLoading: false,
      };
    case GET_LAWYER_GALAXY:
      return {
        ...state,
        isLoading: 6,
      };
    case GET_LAWYER_GALAXY_SUCCESS:
      return {
        ...state,
        galaxy: action.payload,
        isLoading: false,
      };

    case UPLOAD_FILE_SUCCESS:
      NotificationManager.success(
        action.payload.slice(
          action.payload.lastIndexOf('/_files') + 6,
          action.payload.length
        ),
        'File Uploaded Successfully',
        3000,
        null,
        null,
        ''
      );
      return {
        ...state,
        isLoading: false,
        selectedCaseDocuments: [
          ...state.selectedCaseDocuments.map((doc) => {
            if (doc.file_type_id === state.selectedFile) {
              /* eslint no-param-reassign: "error" */
              doc.filename = action.payload.slice(
                action.payload.indexOf('_files') + 7,
                action.payload.length
              );
              return { ...doc };
            }
            return { ...doc };
          }),
        ],
      };
    case GET_COMPANY_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload.data,
        companyPolicy: null,
        companyDetail: null,
        companyFinance: null,
        companyDeepDive: null,
        policyData: null,
        lawyerNews: null,
        galaxy: null,
        isLoading: false,
      };
    case GET_COMPANY_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: action.payload.data[0],
        companyPolicy: action.payload.policy_scores,
        policyData: action.payload.policy_scores_data,
        companyFinance: action.payload.finance_data,
        companyDeepDive: action.payload.deep_dive,
        lawyerNews: null,
        galaxy: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
