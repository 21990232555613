import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';

import {
  POST_NEW_CASE,
  GET_CASE_LIST,
  UPLOAD_FILE,
  GET_CASE_REPORT,
  GET_CASE_DETAIL,
  GET_LAWYER_NEWS,
  GET_LAWYER_GALAXY,
  POST_KYC_DETAILS,
  GET_COMPANY_LIST,
  GET_COMPANY_DETAIL,
} from '../contants';
import {
  postNewCaseSuccess,
  getCaseListSuccess,
  uploadFileSuccess,
  getLawyerGalaxySuccess,
  getCaseReportSuccess,
  getCaseDetailSuccess,
  getLawyerNewsSuccess,
  postKYCBasicDetailsSuccess,
  getCompanyListSuccess,
  getCompanyDetailSuccess,
} from './actions';

const postNewCaseAsync = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/add-case`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
        data: payload,
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getCaseListAsync = async (id) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/case-list/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const uploadFileAsync = async (payload) => {
  const doc = new FormData();
  doc.append('file', payload.file);

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/case-upload/?case_id=${payload.case_id}&lawyer_id=${payload.lawyer_id}&file_type_id=${payload.file_type_id}`,
        method: 'POST',
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          // Add any auth token here
        },
        data: doc,
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getCaseReportAsync = async (caseId) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/case-details_report/${caseId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getLawyerNewsAsync = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/news/${payload.company_id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getCaseDetailAsync = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/case-details/${payload.lawyerId}/${payload.caseId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getLawyerGalaxyAsync = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/company_galaxy/${payload.company_id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const postKYCDetailsAsync = async (payload) => {
  const doc = new FormData();
  doc.append('file', payload.file);
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/add-kyc-details?user_name=${payload.user_name}&user_birthdate=${payload.user_birthdate}&user_qatar_id=${payload.user_qatar_id}&user_address=${payload.user_address}&user_id=${payload.user_id}`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          // Add any auth token here
        },
        data: doc,
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getCompanyListAsync = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/company-list`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

const getCompanyDetailAsync = async (payload) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    success(
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/company-info/${payload.id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any auth token here
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        })
    );
  })
    .then((user) => user)
    .catch((error) => error);
};

function* postNewCase({ payload }) {
  try {
    const postCaseResponce = yield call(postNewCaseAsync, payload);
    if (postCaseResponce.data.status === 201)
      NotificationManager.success(
        '',
        'New Case Registered Successfully',
        3000,
        null,
        null,
        ''
      );
    yield put(postNewCaseSuccess(postCaseResponce));
    const getCaseListResponce = yield call(getCaseListAsync, payload.lawyer_id);

    yield put(getCaseListSuccess(getCaseListResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* getCaseList({ payload }) {
  try {
    const getCaseListResponce = yield call(getCaseListAsync, payload.id);
    yield put(getCaseListSuccess(getCaseListResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* uploadFile({ payload }) {
  try {
    const uploadFileResponce = yield call(uploadFileAsync, payload);
    // if(uploadFileResponce.data.file_path) NotificationManager
    yield put(uploadFileSuccess(uploadFileResponce.data.file_path));
  } catch (error) {
    console.log(error);
  }
}

function* getCaseReport({ payload }) {
  try {
    const getCaseReportResponce = yield call(getCaseReportAsync, payload);
    yield put(getCaseReportSuccess(getCaseReportResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* getCaseDetail({ payload }) {
  try {
    const getCaseDetailResponce = yield call(getCaseDetailAsync, payload);
    yield put(getCaseDetailSuccess(getCaseDetailResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* getLawyerNews({ payload }) {
  try {
    const getLawyerNewsResponce = yield call(getLawyerNewsAsync, payload);
    yield put(getLawyerNewsSuccess(getLawyerNewsResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* getLawyerGalaxy({ payload }) {
  try {
    const getLawyerGalaxyResponce = yield call(getLawyerGalaxyAsync, payload);
    yield put(getLawyerGalaxySuccess(getLawyerGalaxyResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* postKYCDetails({ payload }) {
  try {
    const postKYCDetailsResponce = yield call(postKYCDetailsAsync, payload);
    yield put(postKYCBasicDetailsSuccess(postKYCDetailsResponce.data));
  } catch (error) {
    console.log(error);
  }
}

// function* postKYCDetails({ payload }) {
//   try {
//     const postKYCDetailsResponce = yield call(postKYCDetailsAsync, payload);
//     yield put(postKYCBasicDetailsSuccess(postKYCDetailsResponce.data));
//   } catch (error) {
//     console.log(error);
//   }
// }

function* getCompanyList() {
  try {
    const getCompanyListResponce = yield call(getCompanyListAsync);
    yield put(getCompanyListSuccess(getCompanyListResponce.data));
  } catch (error) {
    console.log(error);
  }
}

function* getCompanyDetail({ payload }) {
  try {
    const getCompanyDetailResponce = yield call(getCompanyDetailAsync, payload);
    yield put(getCompanyDetailSuccess(getCompanyDetailResponce.data));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPostNewCase() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(POST_NEW_CASE, postNewCase);
}

export function* watchGetCaseList() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CASE_LIST, getCaseList);
}

export function* watchUploadFile() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPLOAD_FILE, uploadFile);
}

export function* watchGetCaseReport() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CASE_REPORT, getCaseReport);
}

export function* watchGetCaseDetail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CASE_DETAIL, getCaseDetail);
}

export function* watchGetLawyerNews() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_LAWYER_NEWS, getLawyerNews);
}

export function* watchGetLawyerGalaxy() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_LAWYER_GALAXY, getLawyerGalaxy);
}

export function* watchPostKYCDetails() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(POST_KYC_DETAILS, postKYCDetails);
}

export function* watchGetCompanyList() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_COMPANY_LIST, getCompanyList);
}

export function* watchGetCompanyDetail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_COMPANY_DETAIL, getCompanyDetail);
}

export default function* rootSaga() {
  yield all([
    fork(watchPostNewCase),
    fork(watchGetCaseList),
    fork(watchUploadFile),
    fork(watchGetCaseReport),
    fork(watchGetCaseDetail),
    fork(watchGetLawyerNews),
    fork(watchGetLawyerGalaxy),
    fork(watchPostKYCDetails),
    fork(watchGetCompanyList),
    fork(watchGetCompanyDetail),
  ]);
}
// /case-details_report/{case_id}
