import {
  FETCH_COMPANY_DETAIL_DATA,
  FETCH_TRUST_PILOT_REVIEWS,
  SET_COMPANY_DETAILS,
  SET_COMPANY_DEEP_DIVE,
  FETCH_TRUST_PILOT_REVIEWS_SUCCESS,
  FETCH_COMPANY_NEWS_DATA,
  SET_COMPANY_OFFICER_LIST,
  SET_OFFICER_GALAXY,
  FETCH_COMPANY_NEWS_DATA_SUCCESS,
  SET_SUPPLY_GALAXY,
  SET_SIGNALS_DATA,
  FETCH_NEWS_DATA,
  SET_NEWS_LIST_DATA,
  SET_NEWS_DATA,
  FETCH_EMPLOYEE_COUNT,
  FETCH_USER_COMMENTS,
  FETCH_USER_COMMENTS_SUCCESS,
  POST_USER_COMMENT,
  POST_USER_COMMENT_SUCCESS,
  FETCH_EMPLOYEE_COUNT_SUCCESS,
  FETCH_WEB_TRAFFIC_DETAIL,
  FETCH_DISTRESS_DETAIL,
  FETCH_COMPETITORS_DETAIL,
  FETCH_SUPPLY_CHAIN_DETAIL,
  FETCH_WEB_TRAFFIC_DETAIL_SUCCESS,
  FETCH_DISTRESS_DETAIL_SUCCESS,
  FETCH_COMPETITORS_DETAIL_SUCCESS,
  FETCH_CUSTOMER_DATA,
  FETCH_CUSTOMER_DATA_SUCCESS,
  FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS,
  ADD_COMPANY_DETAIL_ACTION,
  ADD_COMPANY_DETAIL_ACTION_SUCCESS,
  UPDATE_COMPANY_DETAIL_ACTION,
  UPDATE_COMPANY_DETAIL_ACTION_SUCCESS,
  DELETE_COMPANY_DETAIL_ACTION_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  EDIT_COMMENT,
  DELETE_COMMENT
} from '../contants';

export const getCompanyOfficerDetails = (res) => ({
  type: SET_COMPANY_OFFICER_LIST,
  payload: res,
});

export const getCompanyDeepDive = (res) => ({
  type: SET_COMPANY_DEEP_DIVE,
  payload: res,
});

export const getSignalsDetails = (res) => ({
  type: SET_SIGNALS_DATA,
  payload: res,
});

export const fetchNewsData = (res) => ({
  type: FETCH_NEWS_DATA,
  payload: res,
});

export const getNews = (res) => ({
  type: SET_NEWS_LIST_DATA,
  payload: res,
});

export const getNews1 = (res) => ({
  type: SET_NEWS_DATA,
  payload: res,
});

export const fetchCompanyDetail = (data) => ({
  type: FETCH_COMPANY_DETAIL_DATA,
  payload: data,
});

export const fetchTrustPilotReviews = (data) => ({
  type: FETCH_TRUST_PILOT_REVIEWS,
  payload: data,
});

export const fetchTrustPilotReviewsSuccess = (data) => ({
  type: FETCH_TRUST_PILOT_REVIEWS_SUCCESS,
  payload: data,
});

export const getCompanyNewsSuccess = (data) => ({
  type: FETCH_COMPANY_NEWS_DATA_SUCCESS,
  payload: data,
});

export const fetchCompanyNews = (data) => ({
  type: FETCH_COMPANY_NEWS_DATA,
  payload: data,
});

export const getCompanyDetails = (data) => ({
  type: SET_COMPANY_DETAILS,
  payload: data,
});

export const getOfficersGalaxy = (data) => ({
  type: SET_OFFICER_GALAXY,
  payload: data,
});

export const getSupplysGalaxy = (data) => ({
  type: SET_SUPPLY_GALAXY,
  payload: data,
});

export const fetchUserComments = (ids) => ({
  type: FETCH_USER_COMMENTS,
  payload: ids,
});

export const getUserCommentsSuccess = (data) => ({
  type: FETCH_USER_COMMENTS_SUCCESS,
  payload: data,
});

export const postUserComment = (data) => ({
  type: POST_USER_COMMENT,
  payload: data,
});

export const postUserCommentSuccess = () => ({
  type: POST_USER_COMMENT_SUCCESS,
});

export const fetchWebTrafficDetail = (id) => ({
  type: FETCH_WEB_TRAFFIC_DETAIL,
  payload: id,
});

export const fetchDistressDetail = (id) => ({
  type: FETCH_DISTRESS_DETAIL,
  payload: id,
});

export const fetchCompetitorsDetail = (id) => ({
  type: FETCH_COMPETITORS_DETAIL,
  payload: id,
});

export const fetchEmployeeCount = (id) => ({
  type: FETCH_EMPLOYEE_COUNT,
  payload: id,
});

export const fetchEmployeeCountSuccess = (data) => ({
  type: FETCH_EMPLOYEE_COUNT_SUCCESS,
  payload: data,
});

export const fetchCustomerData = (id) => ({
  type: FETCH_CUSTOMER_DATA,
  payload: id,
});

export const fetchCustomerDataSuccess = (data) => ({
  type: FETCH_CUSTOMER_DATA_SUCCESS,
  payload: data,
});

export const fetchSupplyDetail = (id) => ({
  type: FETCH_SUPPLY_CHAIN_DETAIL,
  payload: id,
});

export const fetchWebTrafficDetailSuccess = (data) => ({
  type: FETCH_WEB_TRAFFIC_DETAIL_SUCCESS,
  payload: data,
});

export const fetchDistressDetailSuccess = (data) => ({
  type: FETCH_DISTRESS_DETAIL_SUCCESS,
  payload: data,
});

export const fetchCompetitorsDetailSuccess = (data) => ({
  type: FETCH_COMPETITORS_DETAIL_SUCCESS,
  payload: data,
});

export const fetchSupplyDetailSuccess = (data) => ({
  type: FETCH_SUPPLY_CHAIN_DETAIL_SUCCESS,
  payload: data,
});

export const addCompanyDetailActionByUser = (data) => ({
  type: ADD_COMPANY_DETAIL_ACTION,
  payload: data,
});

export const addCompanyDetailActionByUserSuccess = (data) => ({
  type: ADD_COMPANY_DETAIL_ACTION_SUCCESS,
  payload: data,
});

export const updateCompanyDetailActionByUser = (data) => ({
  type: UPDATE_COMPANY_DETAIL_ACTION,
  payload: data,
});


export const updateCompanyDetailActionByUserSuccess = (data) => ({
  type: UPDATE_COMPANY_DETAIL_ACTION_SUCCESS,
  payload: data,
});

export const deleteCompanyDetailActionByUserSuccess = (data) => ({
  type: DELETE_COMPANY_DETAIL_ACTION_SUCCESS,
  payload: data,
});

export const editComment = (data) => ({
  type: EDIT_COMMENT,
  payload: data,
});


export const editCommentSuccess = () => ({
  type: EDIT_COMMENT_SUCCESS
});

export const deleteComment = (data) => ({
  type: DELETE_COMMENT,
  payload: data,
});

export const deleteCommentSuccess = (data) => ({
  type: DELETE_COMMENT_SUCCESS,
  payload: data,
});

